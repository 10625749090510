angular.module('fingerink')
    .service('createContactGroupModal', function ($uibModal) {
        this.modalInstance = null;
        this.openModal = function () {
            this.modalInstance = $uibModal.open({
                backdrop: 'static',
                templateUrl: 'modals/createContactGroupModal.tpl.html',
                controller: 'createContactGroupModalCtrl',
                animation: false,
                size: 'xl'
            });

            return this.modalInstance;
        };

    })
    .controller('createContactGroupModalCtrl', function createContactGroupModalCtrl($rootScope, $scope, $uibModalInstance, contactService, $translate) {
        $scope.nuevogrupo = {};
        $scope.creandogrupo = false;
        $scope.crearGrupo = function () {
            $scope.creandogrupo = true;
            contactService.addContactoGrupo($scope.nuevogrupo).then(function (response) {
                swal($translate.instant('¡Correcto!'), $translate.instant('Grupo creado correctamente'), 'success');
                $uibModalInstance.close(true);
            });
        };

        $scope.close = function () {
            $uibModalInstance.close(false);
        };

    });
